import {Injectable} from '@angular/core';
import {ApiService} from "../../xstudioz/services/api.service";
import {ISettings} from "../ISettings";

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  public settings: ISettings;

  constructor(public api: ApiService) {
    this.load();
  }

  load() {
    this.api.get('app-settings', {})
      .subscribe((res: ISettings) => {
        this.settings = res;
      })
  }
}
